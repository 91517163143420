import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import MenuModal from '../../modals/MenuModal/MenuModal';
import {MuseumLogoBlack, MuseumLogoWhite} from '../../../assets/icons';
import './Header.css';

import ButtonPill from '../ButtonPill/ButtonPill';

const translateImageSource = (shortSource: string) => {
  return `../../../assets/images/${shortSource}`;
};

interface HeaderProps {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({
  language,
  setLanguage,
}: HeaderProps) => {
  const [menuButtonVisible, setMenuButtonVisible] = useState(!!language);

  const [showMenuButtonClass, setShowMenuButtonClass] = useState('');

  const [modalVisible, setModalVisible] = useState(false);
  const [whiteLogo, setWhiteLogo] = useState(false);

  useEffect(() => {
    setMenuButtonVisible(!!language);
  }, [language]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      setMenuButtonVisible(
        localStorage.getItem('i18nextLng') === 'el' ||
          localStorage.getItem('i18nextLng') === 'en',
      );
    });
  }, []);

  useEffect(() => {
    setShowMenuButtonClass(menuButtonVisible ? '' : 'display-none');
  }, [menuButtonVisible]);

  const getMuseumLogo = () => {
    switch (language) {
      case 'en':
        return whiteLogo
          ? translateImageSource('capo-logo-en.png')
          : translateImageSource('capo-logo-en-black.png');
      default:
        return whiteLogo ? MuseumLogoWhite : MuseumLogoBlack;
    }
  };

  return (
    <div className="app-header">
      <Link
        onClick={() => setModalVisible(false)}
        to="/"
        className="link-header">
        <div
          className="logo-button"
          onMouseOver={() => {
            setWhiteLogo(true);
          }}
          onMouseOut={() => {
            setWhiteLogo(false);
          }}
          onFocus={() => {}}
          onBlur={() => {}}>
          <img
            className="logo-image"
            src={getMuseumLogo()}
            onMouseOver={() => {
              setWhiteLogo(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            onMouseOut={() => {
              setWhiteLogo(false);
            }}
            alt="Μουσείο Καποδίστρια | Κέρκυρα"
          />
        </div>
      </Link>
      <ButtonPill
        className={` ${showMenuButtonClass} header-menu-button`}
        onClick={() => setModalVisible(true)}>
        MENU
      </ButtonPill>

      <MenuModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        language={language}
        setLanguage={setLanguage}
      />
    </div>
  );
};

export default Header;
