import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import ButtonPill from '../ButtonPill/ButtonPill';

import './LanguageSelector.css';

interface LanguageSelectorProps {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  light?: boolean;
  navigate?: boolean;
}

const defaultProps = {
  light: false,
  navigate: false,
};

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  light,
  navigate,
  language,
  setLanguage,
}: LanguageSelectorProps) => {
  const {i18n} = useTranslation();

  const changeLanguage = (lng: string) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  const [enSelected, setEnSelected] = useState(false);
  const [elSelected, setElSelected] = useState(false);

  useEffect(
    function determineSelectedLang() {
      switch (language) {
        case 'el':
          setElSelected(true);
          setEnSelected(false);
          break;
        case 'en':
          setEnSelected(true);
          setElSelected(false);
          break;
        default:
          setEnSelected(false);
          setElSelected(false);
      }
    },
    [language],
  );

  return (
    <div className="lang-selector">
      <Link to={navigate ? '/map' : ''}>
        <ButtonPill
          selected={elSelected}
          light={light}
          type="button"
          onClick={() => changeLanguage('el')}>
          ΕΛΛΗΝΙΚΑ
        </ButtonPill>
      </Link>
      <Link to={navigate ? '/map' : ''}>
        <ButtonPill
          selected={enSelected}
          light={light}
          type="button"
          onClick={() => changeLanguage('en')}>
          ENGLISH
        </ButtonPill>
      </Link>
    </div>
  );
};

LanguageSelector.defaultProps = defaultProps;

export default LanguageSelector;
