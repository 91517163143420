import React from 'react';

import './DetailHeader.css';

interface DetailHeaderProps {
  children: string;
}

const Header: React.FC<DetailHeaderProps> = ({children}: DetailHeaderProps) => {
  return <h1 className="detail-header">{children}</h1>;
};

export default Header;
