import colors from './colors';

// @ts-ignore
const loadRoute = (map, routeCoordinates) => {
  map.addSource('route', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        // @ts-ignore
        coordinates: routeCoordinates,
      },
    },
  });

  map.addLayer({
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': colors.orange,
      'line-width': 3.5,
      'line-opacity': 0.9,
    },
  });
};

export default loadRoute;
