import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {Link, useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
// components
import ButtonPill from '../../common/ButtonPill/ButtonPill';
import DetailImage from '../../common/DetailImage/DetailΙmage';
import AudioPlayer from '../../common/AudioPlayer/AudioPlayer';
import VideoPlayer from '../../common/AudioPlayer/VideoPlayer';
import DetailHeader from '../../common/DetailHeader/DetailHeader';
import DetailDescription from '../../common/DetailDescription/DetailDescription';
import DetailTagline from '../../common/DetailTagline/DetailTagline';
import PoiDetailPhotoGallery from './PoiDetailPhotoGallery';
import SocialMediaButtons from '../../common/SocialMediaButtons/SocialMediaButtons';
// helper methods
import {
  transformPoiImageDataToArray,
  translateAudioSource,
  transformPoiImageSource,
  findPoiById,
} from '../../../util/helperMethods';
// types
import {CaptionedImage, Poi} from '../../../types';
// styles
import './PoiDetailModal.css';

interface PoiDetailModalProps {
  setPoiDetailModalVisible: (arg: boolean) => void;
  poiDetailModalVisible: boolean;
  onClick: () => void;
  selectedPoiId: string;
  setSelectedPoi: (arg: Poi) => void;
  selectedPoi: Poi;
  currentPopUpRef: any;
}

const PoiDetailModal: React.FC<PoiDetailModalProps> = ({
  setPoiDetailModalVisible,
  poiDetailModalVisible,
  onClick,
  selectedPoiId,
  selectedPoi,
  setSelectedPoi,
  currentPopUpRef,
}: PoiDetailModalProps) => {
  const {t: translate, i18n: i18next} = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isPhotoGalleryOpen, setIsPhotoGalleryOpen] = useState(false);
  const [images, setImages] = useState<CaptionedImage[]>([]);
  // const [poiData, setPoiData] = useState<Poi>();

  const {params, url} = useRouteMatch();
  let matchPoiId = '';
  // @ts-ignore
  if (params.id !== 'museum-info' && params.id !== 'poi-list') {
    // @ts-ignore
    matchPoiId = params.id;
  }

  useEffect(() => {
    if (selectedPoi?.id !== selectedPoiId || images[0]?.id !== selectedPoiId) {
      setIsLoading(true);
      if (selectedPoiId && findPoiById(selectedPoiId, i18next.language)) {
        setSelectedPoi(findPoiById(selectedPoiId, i18next.language) as Poi);
        setImages(
          transformPoiImageDataToArray(
            findPoiById(selectedPoiId, i18next.language) as Poi,
          ),
        );
      }
    }

    if (selectedPoi?.id === selectedPoiId) {
      if (
        transformPoiImageDataToArray(
          findPoiById(selectedPoiId, i18next.language) as Poi,
        )?.length > 0 &&
        images &&
        images.length > 0
      ) {
        if (images[0].id === selectedPoiId) {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }
  }, [
    images,
    currentPopUpRef,
    matchPoiId,
    poiDetailModalVisible,
    selectedPoi.id,
    selectedPoiId,
    setSelectedPoi,
    i18next.language,
  ]);

  const audioPlayerConfig = {
    file: {
      forceAudio: true,
    },
  };
  const videoPlayerConfig = {
    youtube: {
      playerVars: {showinfo: 0},
    },
  };
  const getTransTextByIdKey = (id: string, key: string) => {
    return translate(`poiData[${id}].${key}`);
  };

  const showHideClassName = matchPoiId ? 'display-flex' : 'display-none';

  // console.warn(url.split('/'));

  return createPortal(
    <div
      className={`poi-detail-body orange-bg-color ${showHideClassName}`}
      /* onClick={onClick} */
    >
      <Link
        className="poi-detail-close-btn-link"
        to={`/${url.split('/')[1]}`}
        onClick={() => setPoiDetailModalVisible(false)}>
        <ButtonPill className="poi-detail-close-btn">X</ButtonPill>
      </Link>

      {isLoading ? (
        <div className="poi-loader">Loading...</div>
      ) : (
        <div className="poi-detail-wrapper">
          {images.length > 0 && (
            <DetailImage
              src={transformPoiImageSource(images[0].image)}
              alt={images[0].caption}
            />
          )}
          {!(images.length > 0) && <br />}
          {!!selectedPoi && !!selectedPoi?.id && (
            <AudioPlayer
              url={translateAudioSource(i18next.language, selectedPoi.id)}
              config={audioPlayerConfig}
              shouldPlay={!!poiDetailModalVisible}
            />
          )}

          {!!selectedPoi && !!selectedPoi?.title && (
            <DetailHeader>{selectedPoi.title}</DetailHeader>
          )}
          {/* {!!selectedPoi && !!selectedPoi.tagline && (
            <DetailTagline>{selectedPoi.tagline}</DetailTagline>
          )} */}
          {!!selectedPoi && !!selectedPoi?.text && (
            <DetailDescription>{selectedPoi.text}</DetailDescription>
          )}
          {!!selectedPoi && !!selectedPoi?.video && (
            <VideoPlayer url={selectedPoi.video} config={videoPlayerConfig} />
          )}
          {!!selectedPoi && !!selectedPoi?.video_2 && (
            <VideoPlayer url={selectedPoi.video_2} config={videoPlayerConfig} />
          )}
          {!!selectedPoi && !!selectedPoi?.video_3 && (
            <VideoPlayer url={selectedPoi.video_3} config={videoPlayerConfig} />
          )}
          {images.length > 1 && (
            <PoiDetailPhotoGallery
              images={images.slice(1)}
              isOpen={isPhotoGalleryOpen}
              setIsOpen={setIsPhotoGalleryOpen}
            />
          )}
          <SocialMediaButtons sources={['sources']} />
        </div>
      )}
    </div>,
    document.getElementById('modal_root') as Element,
  );
};

export default PoiDetailModal;
