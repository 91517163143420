import React from 'react';
import './Loader.css';

// loading component for suspense fallback
const Loader: React.FC = () => (
  <div className="loading">
    <div className="loader" />
  </div>
);

export default Loader;
