import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useRouteMatch} from 'react-router-dom';
import './InfoModal.css';

import ButtonPill from '../../common/ButtonPill/ButtonPill';
import DetailΙmage from '../../common/DetailImage/DetailΙmage';

import Header from '../../common/DetailHeader/DetailHeader';
import Description from '../../common/DetailDescription/DetailDescription';
import Tagline from '../../common/DetailTagline/DetailTagline';
import SocialMediaButtons from '../../common/SocialMediaButtons/SocialMediaButtons';

interface InfoModalProps {
  setInfoModalVisible: (arg: boolean) => void;
  infoModalVisible: boolean;
}

const InfoModal = ({infoModalVisible, setInfoModalVisible}: InfoModalProps) => {
  const {t: translate} = useTranslation();

  const {params, url} = useRouteMatch();
  let matchPoiId = '';
  // @ts-ignore
  if (params.id === 'museum-info') {
    // @ts-ignore
    matchPoiId = params.id;
  }

  const showHideClassName = matchPoiId ? 'display-flex' : 'display-none';

  return (
    <div className={`info-body orange-bg-color ${showHideClassName}`}>
      <Link
        className="info-close-btn-link"
        to={`/${url.split('/')[1]}`}
        onClick={() => setInfoModalVisible(false)}>
        <ButtonPill className="info-close-btn">X</ButtonPill>
      </Link>

      <div className="info-wrapper">
        <DetailΙmage
          src="/assets/images/museum.jpg"
          alt="Capodistrias Museum"
        />
        <Header>{translate('museumInfo.title')}</Header>
        <Tagline>{translate('museumInfo.tagline')}</Tagline>
        <Description>{translate('museumInfo.description')}</Description>
        <SocialMediaButtons sources={['sources']} />
      </div>
    </div>
  );
};

export default InfoModal;
