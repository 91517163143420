import React from 'react';

import './HeadingsSection.css';

const HeadingsSection: React.FC = () => {
  return (
    <div className="hp-headings-container">
      <h1 className="hp-headings-title">
        <span>KOUKOURITSA GARDEN</span>
        <span>VIRTUAL TOUR</span>
      </h1>
      <h3 className="hp-headings-subtitle">Περιήγηση στο κτήμα Κουκουρίτσα</h3>
      <p className="hp-headings-info">
        Στην εφαρμογή αυτή θα βρείτε μια σειρά από σημεία ειδικού ενδιαφέροντος
        στον κήπο της Κουκουρίτσας. Τα σημεία αυτά συνθέτουν μία διαδρομή που
        μπορείτε να ακολουθήσετε για να εντοπίσετε τις ειδικές πινακίδες
        σήμανσης. Επιλέγοντας ένα σημείο, έχετε τη δυνατότητα να μάθετε
        περισσότερα για το συγκεκριμένο φυτό, ή το κτίσμα ή τη θέα που
        ξεδιπλώνεται μπροστά σας. Ο κήπος σας περιμένει για να σας αποκαλύψει τα
        μικρά του μυστικά!
      </p>
    </div>
  );
};

export default HeadingsSection;
