import React from 'react';

import LanguageSelector from '../../../common/LanguageSelector/LanguageSelector';

import './LanguageSelectionSection.css';

interface LanguageSelectionSectionProps {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const LanguageSelectionSection: React.FC<LanguageSelectionSectionProps> = ({
  language,
  setLanguage,
}: LanguageSelectionSectionProps) => {
  return (
    <div className="lang-section-container">
      <div className="lang-section-subcontainer">
        <h2 className="lang-section-heading">LANGUAGE SELECTION</h2>
        <h3 className="lang-section-subheading">Επιλογή γλώσσας</h3>
      </div>
      <LanguageSelector
        navigate
        language={language}
        setLanguage={setLanguage}
      />
    </div>
  );
};

export default LanguageSelectionSection;
