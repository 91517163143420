import React from 'react';

import './MenuHeader.css';
import {MuseumLogoWhite} from '../../../assets/icons';

import ButtonPill from '../../common/ButtonPill/ButtonPill';

const translateImageSource = (shortSource: string) => {
  return `../../../assets/images/${shortSource}`;
};

interface MenuHeaderProps {
  setModalVisible: (modalVisible: boolean) => void;
  language: string;
}

const MenuHeader: React.FC<MenuHeaderProps> = ({
  setModalVisible,
  language,
}: MenuHeaderProps) => {
  const getMuseumLogo = () => {
    switch (language) {
      case 'en':
        return translateImageSource('capo-logo-en.png');
      default:
        return MuseumLogoWhite;
    }
  };
  return (
    <div className="menu-header">
      {/* Logo and close button <- margin-left: auto; */}{' '}
      <img src={getMuseumLogo()} alt="Μουσείο Καποδίστρια | Κέρκυρα" />
      <ButtonPill light onClick={() => setModalVisible(false)}>
        X
      </ButtonPill>
    </div>
  );
};

export default MenuHeader;
