import React from 'react';

import './DetailTagline.css';

interface DetailTaglineProps {
  children: string;
}

const Tagline: React.FC<DetailTaglineProps> = ({
  children,
}: DetailTaglineProps) => {
  return <h3 className="detail-tagline">{children}</h3>;
};

export default Tagline;
