import React from 'react';
import parse from 'html-react-parser';

import './DetailDescription.css';

interface DetailDescriptionProps {
  children: string;
}

const DetailDescription: React.FC<DetailDescriptionProps> = ({
  children,
}: DetailDescriptionProps) => {
  return <p className="detail-description">{parse(children)}</p>;
};

export default DetailDescription;
