import React from 'react';
import './SocialMediaButtons.css';

import {Instagram, Facebook, Twitter, Youtube} from '../../../assets/icons';

interface SocialMediaButtonsProps {
  sources: string[];
}

const SocialMediaButtons: React.FC<SocialMediaButtonsProps> = ({
  sources,
}: SocialMediaButtonsProps) => {
  return (
    <div className="social-media-buttons">
      <h4>SHARE</h4>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://www.instagram.com/capodistriasmuseum/"
            rel="noreferrer">
            <img src={Instagram} alt="Instagram logo" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.facebook.com/capodistriasmuseum/"
            rel="noreferrer">
            <img src={Facebook} alt="Facebook logo" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://twitter.com/capodistriasm"
            rel="noreferrer">
            <img src={Twitter} alt="Twitter logo" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCMiyCL3i14i2NR4qEMn_pfQ"
            rel="noreferrer">
            <img src={Youtube} alt="Youtube logo" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMediaButtons;
