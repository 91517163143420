// data assets
import tour_el_data from '../assets/tour_el_data.json';
import tour_en_data from '../assets/tour_en_data.json';
// types
import {CaptionedImage, Poi} from '../types';

export const translateImageSource = (shortSource: string) => {
  return `../../../assets/images/${shortSource}.jpg`;
};

export const transformPoiImageSource = (shortSource: string) => {
  return `../../../assets/images/photos/${shortSource}.jpg`;
};

export const transformPoiImageDataToArray = (poi: Poi): CaptionedImage[] => {
  const poiImages = [];
  if (poi) {
    if (poi.image !== '') {
      poiImages.push({id: poi.id, image: poi.image, caption: poi.caption});
    }
    if (poi.image_2 !== '') {
      poiImages.push({id: poi.id, image: poi.image_2, caption: poi.caption_2});
    }
    if (poi.image_3 !== '') {
      poiImages.push({id: poi.id, image: poi.image_3, caption: poi.caption_3});
    }
    if (poi.image_4 !== '') {
      poiImages.push({id: poi.id, image: poi.image_4, caption: poi.caption_4});
    }
  }
  return poiImages;
};

export const translateAudioSource = (lang: string, shortSource: string) => {
  return `/assets/audio/${lang}/${shortSource}.mp3`;
};

export const getDataByLang = (languageCode: string) => {
  if (languageCode === 'en') {
    return tour_en_data.map((poi, index) => {
      return {
        ...poi,
        title: `(${index + 1}/${tour_en_data.length}) ${poi.title}`,
      };
    });
  }
  return tour_el_data.map((poi, index) => {
    return {
      ...poi,
      title: `(${index + 1}/${tour_el_data.length}) ${poi.title}`,
    };
  });
};

export const findPoiById = function (
  id: string,
  languageCode: string,
): Poi | undefined {
  return getDataByLang(languageCode).find(poi => poi.id === id);
};

export const getFirstPoi = (languageCode: string): Poi => {
  return getDataByLang(languageCode)[0];
};
