// @ts-ignore
const loadImageLayer = (map, url, coordinates) => {
  map.addSource('KapodistriasGardenMapImage', {
    type: 'image',
    url,
    coordinates,
  });

  map.addLayer({
    id: 'overlay',
    source: 'KapodistriasGardenMapImage',
    type: 'raster',
    paint: {
      'raster-opacity': 1,
    },
  });
};

export default loadImageLayer;
