const getCategoryImageSource = (category: string) => {
  if (category === '1') {
    return `../assets/images/tree-marker.png`;
  }
  if (category === '2') {
    return `../assets/images/building-marker.png`;
  }
  return `../assets/images/eye-marker.png`;
};

// @ts-ignore
const loadPOIs = (map, poiData) => {
  // @ts-ignore
  poiData.forEach((poi, index) => {
    // add the data source for new a feature collection with no features
    map.addSource(`source-${poi.id}`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [poi.longitude, poi.latitude],
            },
            properties: {
              id: poi.id,
              title: poi.title,
              text: poi.text,
            },
          },
        ],
      },
    });

    // load custom icon for each poi to be referenced to the layers below
    // @ts-ignore
    map.loadImage(getCategoryImageSource(poi.categories), (error, image) => {
      // Add the image to the map style.
      map.addImage(`image-${poi.id}`, image);
    });

    // now add the layer, and reference the data source above by name
    map.addLayer({
      id: `layer-${poi.id}`,
      source: `source-${poi.id}`,
      type: 'symbol',
      layout: {
        'icon-image': `image-${poi.id}`,
        'icon-padding': 0,
        'icon-allow-overlap': true,
        'icon-size': 1,
      },
    });
  });
};

export default loadPOIs;
