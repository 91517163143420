import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useRouteMatch} from 'react-router-dom';
import './PoiListModal.css';

import ButtonPill from '../../common/ButtonPill/ButtonPill';
import DetailΙmage from '../../common/DetailImage/DetailΙmage';

import Header from '../../common/DetailHeader/DetailHeader';
import Description from '../../common/DetailDescription/DetailDescription';
import Tagline from '../../common/DetailTagline/DetailTagline';
import SocialMediaButtons from '../../common/SocialMediaButtons/SocialMediaButtons';
import VerticalList from '../MenuModal/VerticalList';
import VerticalListItem from '../MenuModal/VerticalListItem';
import {getDataByLang} from '../../../util/helperMethods';

interface PoiListModalProps {
  setPoiListModalVisible: (arg: boolean) => void;
  poiListModalVisible: boolean;
}

const PoiListModal = ({
  poiListModalVisible,
  setPoiListModalVisible,
}: PoiListModalProps) => {
  const {t: translate, i18n: i18next} = useTranslation();

  const {params, url} = useRouteMatch();
  let matchPoiId = '';
  // @ts-ignore
  if (params.id === 'poi-list') {
    // @ts-ignore
    matchPoiId = params.id;
  }

  const showHideClassName = matchPoiId ? 'display-flex' : 'display-none';

  return (
    <div className={`info-body orange-bg-color ${showHideClassName}`}>
      <Link
        className="info-close-btn-link"
        to={`/${url.split('/')[1]}`}
        onClick={() => setPoiListModalVisible(false)}>
        <ButtonPill className="info-close-btn">X</ButtonPill>
      </Link>

      <div className="info-wrapper">
        <DetailΙmage
          src="/assets/images/museum.jpg"
          alt="Capodistrias Museum"
        />
        <Header>{translate('poiList.title')}</Header>
        {/* <PoiList /> */}
        <div>
          <nav>
            <VerticalList className="poi-list">
              {getDataByLang(i18next.language).map(poi => (
                <VerticalListItem
                  key={`VerticalListItem${poi.id}`}
                  // className="poi-list-item"
                  // style={{height: '18px'}}
                >
                  <Link
                    onClick={() => console.log('poi item clicked')}
                    to={`/route/${poi.id}`}
                    className="poi-list-item">
                    {poi.title}
                  </Link>
                </VerticalListItem>
              ))}
            </VerticalList>
          </nav>
        </div>
        <SocialMediaButtons sources={['sources']} />
      </div>
    </div>
  );
};

export default PoiListModal;
