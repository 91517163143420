import React, {useState, forwardRef, useEffect} from 'react';
import {VideoSeekSlider} from './VideoSeekSlider';

import {PlayArrowIcon, PauseIcon, SeekButton} from '../../../assets/icons';

import './AudioPlayerControls.css';

interface AudioPlayerControlsProps {
  onSeek: () => void;
  onSeekMouseDown: () => void;
  onSeekMouseUp: () => void;
  onDuration: () => void;
  onPlayPause: () => void;
  onFastForward: () => void;
  handleSeekChange: () => void;
  format: (arg: number) => string;
  playing: boolean;
  played: number;
  elapsedTime: string;
  totalDuration: string;
  durat: number;
  currentTime: number;
  onChangeDispayFormat: () => void;
}

const AudioPlayerControls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      handleSeekChange,
      onPlayPause,
      onFastForward,
      playing,
      played,
      elapsedTime,
      currentTime,
      totalDuration,
      durat,
      onChangeDispayFormat,
      format,
    }: AudioPlayerControlsProps,
    ref,
  ) => {
    return (
      <div className="controls-wrapper">
        <button type="button" onClick={onPlayPause}>
          {playing ? (
            <img
              className="control-icons"
              src={PauseIcon}
              alt="audio pause button"
            />
          ) : (
            <img
              className="control-icons"
              src={PlayArrowIcon}
              alt="audio play button"
            />
          )}
        </button>
        <span className="elapsed-time-span">{elapsedTime || '00:00'}</span>
        <div className="seek-slider">
          <VideoSeekSlider
            max={durat}
            currentTime={currentTime}
            progress={currentTime}
            onChange={handleSeekChange}
            offset={0}
            secondsPrefix="00:00:"
            minutesPrefix="00:"
          />
        </div>
        <span className="elapsed-time-span">{totalDuration || '00:00'}</span>
      </div>
    );
  },
);

export default AudioPlayerControls;
