import React from 'react';
import {useTranslation} from 'react-i18next';

import './Footer.css';

const Footer: React.FC = () => {
  const {t: translate} = useTranslation();

  return (
    <div className="app-footer">
      <p>{`© ${new Date().getFullYear()} ${translate('footer')}`} </p>
    </div>
  );
};

export default Footer;
