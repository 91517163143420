import React, {CSSProperties} from 'react';
import {useTranslation} from 'react-i18next';
import MapLegendItem from './MapLegendItem';
import {Tree, Eye, Building, Flower} from '../../../assets/icons';

import './MenuMapLegend.css';

import colors from '../../../modules/colors';

interface MenuMapLegendProps {
  className?: string;
  style?: CSSProperties;
}

const MenuMapLegend: React.FC<MenuMapLegendProps> = ({
  style,
  className,
}: MenuMapLegendProps) => {
  const badgeSize = 48;

  const {t: translate} = useTranslation();

  return (
    <div className={`map-legend ${className}`} style={style}>
      <span className="map-legend-header">
        {translate('menuModal.mapLegendHeader')}
      </span>
      <div className="top-border" />
      <div className="items-container">
        <MapLegendItem
          backgroundColor={colors.orange}
          size={badgeSize}
          icon={Tree}
          title={translate('poiCategories.trees')}
        />
        <MapLegendItem
          backgroundColor={colors.green}
          size={badgeSize}
          icon={Building}
          title={translate('poiCategories.buildings')}
        />
        <MapLegendItem
          backgroundColor={colors.gray2}
          size={badgeSize}
          icon={Eye}
          title={translate('poiCategories.views')}
        />
      </div>
    </div>
  );
};

MenuMapLegend.defaultProps = {
  style: undefined,
  className: '',
};

export default MenuMapLegend;
