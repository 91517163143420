import React from 'react';
import './Home.css';

import HeadingsSection from '../../components/features/Home/HeadingsSection/HeadingsSection';
import LanguageSelectionSection from '../../components/features/Home/LanguageSelectionSection/LanguageSelectionSection';

interface HomeProps {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const Home: React.FC<HomeProps> = ({language, setLanguage}: HomeProps) => {
  return (
    <div className="home-body">
      <HeadingsSection />
      <LanguageSelectionSection language={language} setLanguage={setLanguage} />
    </div>
  );
};

export default Home;
