import React, {Suspense, useState, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Loader from './components/common/Loader/Loader';
import Header from './components/common/Header/Header';
import Home from './screens/Home/Home';
import Tour from './screens/Tour/Tour';

import './App.css';
import Footer from './components/common/Footer/Footer';

const App: React.FC = () => {
  const {i18n} = useTranslation();

  const getLanguage = () => {
    switch (localStorage.getItem('i18nextLng')) {
      case 'en':
        return 'en';
      case 'el':
        return 'el';
      default:
        localStorage.removeItem('i18nextLng');
        return '';
    }
  };

  const [language, setLanguage] = useState(getLanguage());

  useEffect(() => {
    if (
      localStorage.getItem('i18nextLng') === 'en' ||
      localStorage.getItem('i18nextLng') === 'el'
    ) {
      setLanguage(localStorage.getItem('i18nextLng') || '');
      i18n.changeLanguage(localStorage.getItem('i18nextLng') || '');
    }
  }, [i18n]);

  useEffect(() => {
    if (language === 'en' || language === 'el') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', language);
    }
  }, [language, i18n]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      setLanguage(localStorage.getItem('i18nextLng') || '');
    });
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <div className="page-parent">
          <header>
            <Header language={language} setLanguage={setLanguage} />
          </header>

          <main>
            <Switch>
              <Route path={['/map/:id', '/map', '/route/:id', '/route']}>
                <Tour setLanguage={setLanguage} />
              </Route>
              <Route path="/">
                <Home language={language} setLanguage={setLanguage} />
              </Route>
              <Route path="/" component={Home} />
            </Switch>
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      </Router>
    </Suspense>
  );
};

export default App;
