import React from 'react';

import BadgeCircle from './BadgeCircle';

import './MapLegendItem.css';

interface MapLegendItemProps {
  icon: string;
  title: string;
  backgroundColor: string;
  size: number;
}

const MapLegendItem: React.FC<MapLegendItemProps> = ({
  title,
  icon,
  backgroundColor,
  size,
}: MapLegendItemProps) => {
  return (
    <div className="map-legend-item">
      <BadgeCircle
        backgroundColor={backgroundColor}
        size={size}
        icon={icon}
        alt={title}
      />
      <span>{title}</span>
    </div>
  );
};

export default MapLegendItem;
