import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import './DetailImage.css';

interface DetailImageProps {
  src: string;
  alt?: string;
}

const DetailImage: React.FC<DetailImageProps> = ({
  src,
  alt,
}: DetailImageProps) => {
  const {t: translate} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [imageSource, setImageSource] = useState(src);

  useEffect(() => {
    setImageSource(src);
  }, [src]);

  return (
    <>
      <div
        className="detail-image"
        style={{display: loading ? 'block' : 'none'}}>
        <div key={`Loading${src}`} className="image-loader">
          {translate('loadingImage')}
        </div>
      </div>
      <img
        key={src}
        onLoad={() => setLoading(false)}
        className="detail-image"
        style={{display: loading ? 'none' : 'block'}}
        src={imageSource}
        alt={alt}
      />
    </>
  );
};

DetailImage.defaultProps = {
  alt: 'Detail Image',
};

export default DetailImage;
