import React, {CSSProperties} from 'react';

import './ButtonPill.css';

// TODO: implement mobile/desktop flag prop

interface ButtonPillProps {
  type?: 'submit' | 'button';
  children?: string;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
  light?: boolean;
  selected?: boolean;
}

const defaultProps: ButtonPillProps = {
  type: 'button',
  children: '',
  onClick: () => {},
  style: {},
  className: '',
  light: false,
  selected: false,
};

const ButtonPill: React.FC<ButtonPillProps> = ({
  children,
  onClick,
  type,
  style,
  className,
  light,
  selected,
}: ButtonPillProps) => {
  const lightClassName = light ? 'light' : '';
  const selectedClassName = selected ? 'selected' : '';
  if (type === 'button')
    return (
      <button
        className={`button-pill ${className} ${lightClassName} ${selectedClassName}`}
        type="button"
        onClick={onClick}
        style={style}>
        <span>{children}</span>
      </button>
    );

  return (
    <button type="submit" onClick={onClick}>
      {children}
    </button>
  );
};

ButtonPill.defaultProps = defaultProps;

export default ButtonPill;
